/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');





:root{
  --bg-color: #f68712;
}
// body{
//     font-family: 'Poppins', sans-serif;
//     background-color: red;
// }
body{
    font-family: 'Poppins', sans-serif;
    // background-color: #f7f6fe;

}
::ng-deep{
  mat-tab-header{
    &.mat-mdc-tab-header{
      background-color: #f68712;
      --background-color: #f68712;
      background: red;
      --background: red;

    }
  }
}
::ng-deep{
  .mat-mdc-tab-group{
    background-color: #f68712;
  }
}

.mdc-tab--active, .alert-button.sc-ion-alert-ios, .alert-button.sc-ion-alert-md{
  background: #ff9700 !important;
  color: #fff !important;
}
.alert-wrapper.sc-ion-alert-md{
  background: #f9f9f9;
}
.alert-radio-label.sc-ion-alert-md{
  color: #000000;
}
[aria-checked=true].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios,
[aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
  color: #ff9700;
}
[aria-checked=true].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios,
[aria-checked=true].sc-ion-alert-md .alert-radio-inner.sc-ion-alert-md{
  border-color: #ff9700;
}
div.mat-mdc-tab:not(.mdc-tab--stacked){
  font-size: 12px;
  line-height: normal;
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

ion-item{
  background-color: #ffffff;
  --background-color: #ffffff;
  --background: #ffffff;
  &.select-interface-option{
    background-color: #ffffff;
    --background-color: #ffffff;
    --background: #ffffff;
    --color: rgb(0, 0, 0);
   }
}




.ion-select-popover{
  background-color: #ffffff;
  --background: #ffffff;
  --background-color: #ffffff;
}
ion-select-popover{
  background-color: #ffffff;
  --background: #ffffff;
  --background-color: #ffffff;
}



.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0
}

.cdk-overlay-container,.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000
}

.cdk-overlay-container:empty {
  display: none
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity .4s cubic-bezier(.25,.8,.25,1);
  opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: .6
}

.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,.32)
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear,opacity 1ms linear;
  visibility: hidden;
  opacity: 1
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible
}

.cdk-overlay-backdrop-noop-animation {
  transition: none
}

.mat-calendar-arrow {
  fill: #0000008a
}

.mat-datepicker-toggle,.mat-datepicker-content .mat-calendar-next-button,.mat-datepicker-content .mat-calendar-previous-button {
  color: #0000008a
}

.mat-calendar-table-header-divider:after {
  background: rgba(0,0,0,.12)
}

.mat-calendar-table-header,.mat-calendar-body-label {
  color: #0000008a
}

.mat-calendar-body-cell-content,.mat-date-range-input-separator {
  color: #000000de;
  border-color: transparent
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #00000061
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: #00000061
}

.mat-calendar-body-in-preview {
  color: #0000003d
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #00000061
}

.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #0000002e
}

.mat-calendar-body-in-range:before {
  background: rgba(63,81,181,.2)
}

.mat-calendar-body-comparison-identical,.mat-calendar-body-in-comparison-range:before {
  background: rgba(249,171,0,.2)
}

.mat-calendar-body-comparison-bridge-start:before,[dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right,rgba(63,81,181,.2) 50%,rgba(249,171,0,.2) 50%)
}

.mat-calendar-body-comparison-bridge-end:before,[dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left,rgba(63,81,181,.2) 50%,rgba(249,171,0,.2) 50%)
}

.mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-calendar-body-in-comparison-range>.mat-calendar-body-selected {
  background: #46a35e
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: #fff
}

.mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: #3f51b566
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #3f51b54d
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color:#3f51b54d
  }
}

.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003,0 4px 5px #00000024,0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range:before {
  background: rgba(255,64,129,.2)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range:before {
  background: rgba(249,171,0,.2)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start:before,.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right,rgba(255,64,129,.2) 50%,rgba(249,171,0,.2) 50%)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end:before,.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left,rgba(255,64,129,.2) 50%,rgba(249,171,0,.2) 50%)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected {
  background: #46a35e
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: #fff
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: #ff408166
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #ff40814d
}

@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color:#ff40814d
  }
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range:before {
  background: rgba(244,67,54,.2)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range:before {
  background: rgba(249,171,0,.2)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start:before,.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right,rgba(244,67,54,.2) 50%,rgba(249,171,0,.2) 50%)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end:before,.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left,rgba(244,67,54,.2) 50%,rgba(249,171,0,.2) 50%)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected {
  background: #46a35e
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: #fff
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: #f4433666
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #f443364d
}

@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color:#f443364d
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0 11px 15px -7px #0003,0 24px 38px 3px #00000024,0 9px 46px 8px #0000001f
}

.mat-datepicker-toggle-active {
  color: #3f51b5
}

.mat-datepicker-toggle-active.mat-accent {
  color: #ff4081
}

.mat-datepicker-toggle-active.mat-warn {
  color: #f44336
}

.mat-date-range-input-inner[disabled] {
  color: #00000061
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none
}

.mat-calendar {
  font-family: Roboto,sans-serif;
}

.mat-calendar-body {
  font-size: 13px
}

.mat-calendar-body-label,.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400
}

.mat-divider {
  border-top-color: #0000001f
}

.mat-divider-vertical {
  border-right-color: #0000001f
}

.mat-expansion-panel {
  background: white;
  color: #000000de
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f
}

.mat-action-row {
  border-top-color: #0000001f
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0,0,0,.04)
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
      background:white
  }
}

.mat-expansion-panel-header-title {
  color: #000000de
}

.mat-expansion-panel-header-description,.mat-expansion-indicator:after {
  color: #0000008a
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: #00000042
}

.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit
}

.mat-expansion-panel-header {
  height: 48px
}

.mat-expansion-panel-header.mat-expanded {
  height: 64px
}

.mat-expansion-panel-header {
  font-family: Roboto,sans-serif;
  font-size: 14px;
  font-weight: 500
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto,sans-serif;
  letter-spacing: .0178571429em
}

:host{
  padding: 0;
}



ion-toolbar{
     .toolbar-container{
        padding: 0;
      }
      }



      .action-sheet-wrapper{
        --max-height: 70%;
        --height: 100%;

        .action-sheet-group{
          --background-color: #ffffff;
          background-color: #ffffff;
          --background: #ffffff;
          --color: #000000;
          color: #000000;
        }
        }
        .action-sheet-button{
          // --background-color: #ff9700;
          // background-color: #ff9700;
          // --background: #ff9700;
        }

        .action-sheet-button.sc-ion-action-sheet-ios{
          // --background-color: #ff9700;
          // background-color: #ff9700;
          // --background: #ff9700;
        }
        // .action-sheet-group.sc-ion-action-sheet-ios:last-child{
        //   --background-color: #ff9700;
        //   background-color: #ff9700;
        //   --background: #ff9700;
        // }

        .action-sheet-cancel.sc-ion-action-sheet-ios{

          .action-sheet-button-inner.sc-ion-action-sheet-ios{
            --color: #ffffff;
            color: #ffffff;
          }
          &::after{
            --background-color: #ff9700;
            background-color: #ff9700;
            --background: #ff9700;

          }
        }

        .action-sheet-selected.sc-ion-action-sheet-ios::after{
          background-color: #ffffff;
          --background-color: #ffffff;
         --background: #ffffff;
        }
        .action-sheet-button.sc-ion-action-sheet-ios{
          --color: #000000;
          color: #000000;
        }






// ::ng-deep{
//   ion-toolbar{
//    .toolbar-container{
//       padding: 0;
//     }
//     }
// }
ion-modal {
  --height: 50%;
  --border-radius: 16px;
  background-color: #ffffff;
  --background: #ffffff;

  color: #000000;
  --color: #000000;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
  color: #000000;
  --color: #000000;
}

ion-modal ion-toolbar {
  --background: rgb(14 116 144);
  --color: rgb(0, 0, 0);
}
.blank-data{
  color:#000000;text-align: center;
  padding-top: 20px;
  margin: 0;

}

.happymodal{
  background: transparent;
}
